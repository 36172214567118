@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import './mixin.scss';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
}

a,
button {
    transition: ease-in-out .5s;
    text-decoration: none;
}

ul {
    padding: 0;
    margin: 0;
}

body {
    font-family: "Nunito", sans-serif;
    color: #000000;
    font-size: 15px;
}

input,
button,
select,
textarea {
    outline: none;
    font-family: "Nunito", sans-serif;
}

.container {
    max-width: 1200px;
    padding: 0 15px;
}

.loginBg {
    width: 500px;
    background: url(../img/login-bg.jpg) no-repeat center bottom;
    background-size: cover;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;

    @include respond-to(media-xl) {
        width: 400px;
    }

    @include respond-to(media-lg) {
        width: 300px;
    }

    @include respond-to(media-md) {
        display: none;
    }
}

.loginDet {
    width: calc(100% - 500px);
    height: 100vh;
    overflow: auto;
    background: url(../img/bg.jpeg);
    background-size: cover;
    position: relative;

    @include respond-to(media-xl) {
        width: calc(100% - 400px);
    }

    @include respond-to(media-lg) {
        width: calc(100% - 300px);
    }

    @include respond-to(media-lg) {
        width: 100%;
    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, .3);
    }
}

.loginInner {
    max-width: 740px;
    width: 100%;
    margin: auto;
    padding: 20px;
    position: relative;

    @include respond-to(media-lg) {
        max-width: 540px;
    }
}

.loginHd {
    color: #3A3A3A;
    font-size: 17px;
    font-weight: 500;

    @include respond-to(media-lg) {
        font-size: 16px;
    }

    span {
        color: #002C39;
        font-size: 32px;
        line-height: 40px;
        font-weight: 800;

        @include respond-to(media-lg) {
            font-size: 28px;
            line-height: 34px;
        }

        @include respond-to(media-sm) {
            font-size: 22px;
            line-height: 30px;
        }
    }
}

.loginBox {
    box-shadow: 2px 5px 40px 0px #0000000D;
    background: #fff;
    border-radius: 4px;

    .hd {
        color: #191919;
        font-size: 22px;
        font-weight: 700;
    }
}

.loginForm {
    margin: -15px;

    li {
        padding: 15px;

        .field {
            color: #172B4D;
            font-size: 12px;
            background: #fff;
            padding: 0 4px;
            position: absolute;
            left: 15px;
            top: -9px;
        }
    }
}

.loginInput {
    border: 1px solid #ACB3BF;
    border-radius: 4px;
    height: 52px;
    width: 100%;
    padding: 0 20px;
    color: #172B4D;
    font-size: 16px;

    &:is(:-webkit-autofill, :autofill) {
        transition: background-color 5000s ease-in-out 0s;
        font-size: 16px;
        color: #172B4D;
        font-family: "Nunito", sans-serif;
    }
}

textarea.loginInput {
    padding: 12px 20px;
    line-height: 20px;
    height: 66px;
}

a.fp {
    color: #8BC34A;
}

.loginBtn {
    background: #8BC34A;
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    padding: 0 25px;
    color: #fff;
    border: none;
    min-width: 140px;

    @include respond-to(media-sm) {
        height: 44px;
        padding: 0 15px;
        min-width: 120px;
    }

    &:hover {
        background: #172B4D;
    }
}

.newuser {
    background: rgba(139, 195, 74, 0.06);
    color: #002C39;
    font-weight: 500;

    a {
        color: #8BC34A;
    }
}

.header {
    box-shadow: 2px 5px 20px 0px #0000000D;
    background: #fff;

    .container {
        height: 72px;
    }

    .logo {
        img {
            width: 166px;
        }
    }
}

.acLink {
    .dropdown-toggle {
        background: none;
        color: #000000;
        font-size: 17px;
        border: none;

        &:focus {
            background: none;
            color: #000000;
        }

        .icon {
            background: #A2BD3A;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            font-size: 10px;
            font-weight: 700;
            color: #fff;
            margin-right: 10px;
        }
    }

    .dropdown-menu {
        a {
            color: #000;

            &:hover {
                background: #8BC34A;
                color: #fff;
            }
        }
    }
}

.midCon {
    width: 100%;
    min-height: calc(100vh - 132px);
    background: url(../img/bg.jpeg);
    background-size: cover;
    position: relative;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, .2);
    }

    .container {
        position: relative;
    }
}

.dbLinks {
    margin: -15px;

    @include respond-to(media-lg) {
        margin: -10px;
    }

    @include respond-to(media-md) {
        margin: -5px;
    }

    li {
        padding: 15px;

        @include respond-to(media-lg) {
            padding: 10px;
        }

        @include respond-to(media-md) {
            padding: 5px;
        }

        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 200px;
            background: #fff;
            border-radius: 4px;
            color: #002C39;
            font-size: 16px;
            font-weight: 500;

            @include respond-to(media-md) {
                height: 160px;
            }

            &:hover {
                box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
                background: rgba(255, 255, 255, 0.5);
            }

            .icon {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 15px;
                height: 64px;
            }
        }
    }
}

.footer {
    box-shadow: 2px 5px 20px 0px #0000000D;
    background: #fff;
    color: #444444;
    font-size: 13px;

    .container {
        height: 60px;

        @include respond-to(media-xs) {
            height: auto;
            padding: 15px;
            text-align: center;
            display: block !important;
        }
    }

    .footerLink {
        a {
            color: #3A3A3A;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
                color: #8BC34A;
            }
        }
    }
}

.bradcrumb {
    color: #3A3A3A;
    font-size: 17px;
    font-weight: 700;

    a {
        color: #3A3A3A;
        font-weight: 500;
    }
}

.filterCon {
    background: #fff;
}

.searchBy {
    width: 100px;
    font-size: 16px;
    font-weight: 700;

    @include respond-to(media-md) {
        width: 100%;
        padding-bottom: 12px;
    }
}

.filterBox {
    width: calc(100% - 100px);
    margin: -10px;
    position: relative;
    padding-right: 60px;

    @include respond-to(media-md) {
        width: 100%;
    }

    @include respond-to(media-sm) {
        margin: -5px;
        padding-right: 0;
    }

    li {
        padding: 10px;

        @include respond-to(media-sm) {
            padding: 5px;
        }

        .field {
            color: #172B4D;
            font-size: 12px;
            line-height: 14px;
            background: #fff;
            padding: 0 4px;
            position: absolute;
            left: 15px;
            bottom: 48px;
        }

        &.btn {
            position: absolute;
            right: -10px;
            top: 0;

            @include respond-to(media-sm) {
                position: static;
                width: 100%;
            }

            button {
                background: #8BC34A;
                width: 54px;
                height: 54px;
                border-radius: 4px;
                color: #fff;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background: #333;
                }

                @include respond-to(media-sm) {
                    width: 100%;
                }
            }
        }
    }
}

.filterInput {
    border: 1px solid #ACB3BF;
    border-radius: 4px;
    height: 56px;
    width: 100%;
    padding: 0 20px;
    color: #172B4D;

    &:is(:-webkit-autofill, :autofill) {
        transition: background-color 5000s ease-in-out 0s;
        font-size: 16px;
        color: #172B4D;
        font-family: "Nunito", sans-serif;
    }
}

.filterSelect {
    border: 1px solid #ACB3BF;
    border-radius: 4px;
    height: 56px;
    width: 100%;
    padding: 0 20px;
    color: #172B4D;
    appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    background: #fff url(../img/select-arrow.png) no-repeat right 10px center;
}

.filterSelect::-ms-expand {
    display: none;
}

.whtBg {
    background: #fff;
}

.tableBox {
    width: 100%;
    overflow: auto;

    table {
        width: 100%;

        th {
            color: #191919;
            font-weight: 500;
            font-size: 14px;
            border-bottom: 1px solid #0000001A;
            padding: 12px 10px;
            white-space: nowrap;
        }

        td {
            color: #696969;
            font-size: 14px;
            border-bottom: 1px solid #0000001A;
            padding: 12px 10px;
        }
    }
}

.actionbtn {
    a {
        background: #81655B0D;
        border-radius: 4px;
        width: 36px;
        height: 36px;
        border: none;
        margin-right: 15px;
        color: #82655B;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: #8BC34A;
            color: #fff;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.totalResul {
    color: #696969;
    font-size: 14px;
    font-weight: 500;
}

.paging {
    a {
        margin: 0 8px;
        color: #696969;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.number {
            background: rgba(139, 195, 74, 0.2);
            width: 42px;
            height: 42px;
            border-radius: 100%;
        }
    }
}

.subHd {
    font-size: 18px;
    font-weight: 700;
}

.viewList {
    margin: -15px;

    li {
        padding: 15px;
        color: #172B4D;
        font-size: 12px;

        span {
            font-size: 16px;
            font-weight: 700;
        }

        span.heading {
            font-size: 20px;
            font-weight: 900;
        }

        .field {
            color: #172B4D;
            font-size: 12px;
            background: #fff;
            padding: 0 4px;
            position: absolute;
            left: 15px;
            top: -9px;
        }
    }
}

.formBtn {
    background: #8BC34A;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    padding: 0 25px;
    border: none;

    &:hover {
        background: #002C39;
    }

    &.cancel {
        background: #F9F7F7;
        color: #82655B;
    }
}

.loader {
    width: 90px;
    height: 14px;
    --c: #fff 92%, #0000;
    background:
        radial-gradient(circle 7px at bottom, var(--c)) 0 0,
        radial-gradient(circle 7px at top, var(--c)) 0 100%;
    background-size: calc(100%/4) 50%;
    background-repeat: repeat-x;
    animation: l11 1s infinite;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -45px;
    opacity: .5;
}

@keyframes l11 {

    80%,
    100% {
        background-position: calc(100%/3) 0, calc(100%/-3) 100%
    }
}


a.linkBtn {
    background: #8BC34A;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    display: inline-flex;
    align-items: center;
    padding: 0 25px;
    border: none;

    &:hover {
        background: #002C39;
    }

    &.cancel {
        background: #F9F7F7;
        color: #82655B;
    }
}

.error {
    color: red;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    max-width: 500px;
}

.btn-link {
    color: inherit;
    text-decoration: none;
}

.btn-link:hover {
    color: #007bff;
}

.samplePopup {
    .modal-body {max-height: calc(100vh - 200px); overflow: auto;
        .list-unstyled {
            li {padding: 5px; font-size: 16px;}
        }
    }
    
}
.ViewButton{
    background: #676867;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    padding: 0 25px;
    border: 1px solid #8BC34A;
}
.ViewButton:hover{
    background: #8BC34A;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    padding: 0 25px;
    border: none;
}